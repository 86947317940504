import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import useDeleteParams from '../../hooks/useDeleteParams';
import { authenticationService, eventService } from '../../services';
import { redirectComWebsite } from '../../utils/appUtils';
import { Event, EventParticipationAnswer } from '../../types/Events';
import { CustomFieldResponses, InviteType } from '../../types/invites';
import { getUserId } from '../../services/httpService';
import { getEventAttendanceAnswers, getEventAttendees, getPollResponses } from '../../utils/eventUtils';
import { formatCustomFieldResponsesFromCustomFields } from '../../utils/formatUtils';
import { LoginPageState, SuccessPageState } from '../../types/navigation';
import ResponsePageWrapper from '../../components/layouts/ResponsePageWrapper';
import EventOverview from '../../components/overviews/EventOverview';
import IconBadge from '../../components/icons/IconBadge';
import GenericInput from '../../components/forms/GenericInput';
import CustomFieldsForm from '../../components/forms/CustomFieldsForm';
import Button from '../../components/misc/Button';
import { InvitationDetails, UserAccountCreationChannel, UserAccountCreationEvent } from '../../analytics/models';
import NameInput from '../../components/forms/profile/NameInput';
import EmailInput from '../../components/forms/profile/EmailInput';
import { Label, ProfileDataType } from '../../types/profile';
import TermsAndConditions from '../../components/misc/TermsAndConditions';

function UnauthenticatedEventRespondPage(): JSX.Element {
  const { t } = useTranslation();
  const [params, _] = useSearchParams();
  const navigate = useNavigate();

  useDeleteParams('link');

  const linkId = params.get('id');

  const [event, setEvent] = useState<Event>();
  const [selectedAnswer, setSelectedAnswer] = useState<EventParticipationAnswer | null>(null);
  const [remark, setRemark] = useState<string>('');
  const [customFieldResponses, setCustomFieldResponses] = useState<CustomFieldResponses>([]);

  const [email, setEmail] = useState<string>('');
  const [alias, setAlias] = useState<string>('');
  const [accountExists, setAccountExists] = useState<boolean>(false);
  const [terms, setTerms] = useState<boolean>(false);
  const [validateFields, setValidateFields] = useState<boolean>(false);

  useEffect(() => {
    eventService
      .getEventInviteWithToken(linkId as string)
      .then((e) => setEvent(e))
      .catch(() => {
        toast.error(t('toast.error.general.inviteNotFound'));
        redirectComWebsite();
      });
  }, []);

  useEffect(() => {
    try {
      if (!event) return;

      if (event.isDatePicker) {
        navigate(`/date-picker/invite?id=${event.id}`);
      }

      const responses: { [p: string]: any } = [[]];
      setCustomFieldResponses(formatCustomFieldResponsesFromCustomFields(event!.customFields, responses));
    } catch (error) {
      console.error(error);
    }
  }, [event]);

  const validate = () => {
    const throwError = (message: string) => {
      toast.error(message);
      return false;
    };

    setValidateFields(true);

    if (!alias || !alias.split('+')[0] || !alias.split('+')[1]) return throwError(t('toast.error.field.mandatoryStar'));

    if (!email) return throwError(t('toast.error.field.mandatoryStar'));

    if (!selectedAnswer || selectedAnswer === EventParticipationAnswer.PENDING)
      return throwError(t('toast.error.field.noAnswer'));

    if (maximumAttendeesReached && selectedAnswer === EventParticipationAnswer.YES)
      return throwError(t('toast.error.events.maximumAttendeesReached'));

    if (!terms) {
      toast.error(t('toast.error.general.terms'));
      return false;
    }

    if (event!.customFields.length > 0) {
      return event!.customFields.every((field) => {
        if (field.mandatory && !customFieldResponses[field.customFieldId]) {
          toast.error(t('toast.error.field.mandatoryStar'));
          return false;
        }
        return true;
      });
    }

    return true;
  };

  const handleSubmit = async (): Promise<void> => {
    if (!validate() || !event?.id) return;

    const registerEvent: UserAccountCreationEvent<InvitationDetails> = {
      channel: UserAccountCreationChannel[UserAccountCreationChannel.EVENT_INVITE],
      details: { inviteLink: linkId || '' },
    };

    await authenticationService.register(alias, email, registerEvent);

    await eventService.respondToEvent(`${event.id}`, selectedAnswer!, remark);

    if (event!.customFields.length > 0) {
      await eventService.respondToEventPollBulk(`${event.id}`, customFieldResponses);
    }

    const state: SuccessPageState = { translationKey: 'page.events.invite.message.success' };
    navigate('/success', { state });
  };

  if (!event) return <></>;

  const handleLogin = async () => {
    const state: LoginPageState = { inviteType: InviteType.EVENT, eventId: `${event.id}`, hideBackButton: true };
    navigate('/login', { state });
  };

  const { maximumAttendees, deadline } = event;

  const numberOfAttendees = getEventAttendees(event);
  const deadlineIsPassed = deadline ? new Date() > new Date(deadline) : false;
  const maximumAttendeesReached = maximumAttendees ? numberOfAttendees >= maximumAttendees : false;
  const remarkPlaceholder = selectedAnswer
    ? `${t(`page.events.invite.placeholder.eg`)} ${t(`page.events.invite.placeholder.${selectedAnswer}.reason${Math.floor(Math.random() * 10) + 1}`)}`
    : '';

  // TODO: Add registration fields and add registration call to submit

  return (
    <ResponsePageWrapper handleLogin={handleLogin}>
      {!deadlineIsPassed && (
        <div className="my-4 flex flex-col gap-4">
          <div className="font-serif text-3xl">{t('page.events.invite.register')}</div>

          <NameInput alias={alias} label setAlias={setAlias} mandatory validate={validateFields} />

          <EmailInput
            field={{
              dataType: ProfileDataType.EMAIL,
              email,
              label: Label.GENERAL,
              description: '',
            }}
            label
            emailCheck
            setAccountExists={setAccountExists}
            setField={(field) => setEmail(field.email!)}
            mandatory
            validate={validateFields}
          />
          {accountExists && (
            <span className="text-sm text-red-500">
              {t('page.shared.emailInUse')}{' '}
              <strong className="cursor-pointer underline" onClick={handleLogin}>
                {t('page.shared.logInHere')}
              </strong>
              .
            </span>
          )}
        </div>
      )}

      <EventOverview event={event}>
        {!deadlineIsPassed && (
          <div className="flex flex-col gap-4">
            <div className="font-serif text-lg">{t('page.events.invite.areYouAttending')}</div>
            <div className="flex gap-4">
              {getEventAttendanceAnswers(i18n).map(([answer, label, icon]) => (
                <button
                  type="button"
                  onClick={() => setSelectedAnswer(answer)}
                  key={answer}
                  disabled={maximumAttendeesReached && answer === EventParticipationAnswer.YES}
                  className={`flex aspect-square w-1/3 flex-col items-center gap-2 ${maximumAttendeesReached && answer === EventParticipationAnswer.YES ? 'opacity-50' : ''}`}>
                  <IconBadge
                    icon={icon}
                    className={`aspect-square w-auto grow rounded-xl outline outline-1 outline-primary-300 ${selectedAnswer === answer ? 'bg-primary' : 'bg-secondary-200'}`}
                    iconClassName="text-primary-300"
                  />
                  <p className="text-sm">{label}</p>
                </button>
              ))}
            </div>
            <div>
              <GenericInput
                label={t('page.events.invite.note')}
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
                placeholder={selectedAnswer !== EventParticipationAnswer.PENDING ? remarkPlaceholder : ''}
                textarea
              />
            </div>

            {event.customFields.length > 0 && (
              <>
                <div className="font-serif text-lg">{t('page.events.invite.polls.title')}</div>
                <CustomFieldsForm
                  fields={event.customFields}
                  response={customFieldResponses}
                  setResponse={setCustomFieldResponses}
                />
              </>
            )}
          </div>
        )}
      </EventOverview>

      {!deadlineIsPassed && <TermsAndConditions className="mt-4" checked={terms} setChecked={setTerms} />}

      {!deadlineIsPassed && (
        <div className="mt-6 flex h-full min-h-10 flex-1 items-end justify-end align-bottom">
          <Button className="underline" onClick={() => redirectComWebsite()}>
            {t('general.cancel')}
          </Button>
          <Button variant="primary" className="px-[32px]" onClick={handleSubmit}>
            {t('general.save')}
          </Button>
        </div>
      )}
    </ResponsePageWrapper>
  );
}

export default UnauthenticatedEventRespondPage;
