/* eslint-disable default-case */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginPageState } from '../../types/navigation';
import { authenticationService } from '../../services';
import ResponsePageWrapper from '../../components/layouts/ResponsePageWrapper';
import { InviteType } from '../../types/invites';
import InviteHeader from '../../components/headers/InviteHeader';
import CommunityInviteHeader from '../../components/headers/CommunityInviteHeader';
import BusinessCardHeader from '../../components/headers/BusinessCardHeader';
import Button from '../../components/misc/Button';
import { redirectComWebsite } from '../../utils/appUtils';

export default function LoginPage(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const {
    email,
    invitationId,
    communityInvitationId,
    inviteType,
    personalLinkId,
    eventId,
    hideBackButton = false,
  }: LoginPageState = location.state || {};

  const [emailInput, setEmailInput] = useState<string>(email || '');

  useEffect(() => {
    if (!location.state) redirectComWebsite();
  }, []);

  const handleSubmit = async () => {
    await authenticationService
      .generateMagicLink(emailInput, invitationId, communityInvitationId, inviteType, personalLinkId, eventId)
      .then(() => toast.success(t('toast.success.login')))
      .catch(() => {});
  };

  const getHeader = (): JSX.Element => {
    if (!location.state) return <div />;
    switch (inviteType) {
      case InviteType.USER:
        const { invite }: LoginPageState = location.state;
        if (invite?.dataRequest.length === 0)
          return (
            <BusinessCardHeader
              requesterAlias={invite?.provider.profile.alias!}
              providedTypes={invite?.dataProvidedTypes}
              profilePicture={invite?.profilePicture}
            />
          );
        return <InviteHeader requesterName={invite?.provider.profile.alias.split('+')[0] || ''} />;
      case InviteType.COMMUNITY:
        const { communityInvite }: LoginPageState = location.state;
        return <CommunityInviteHeader invite={communityInvite!} />;
      case InviteType.LOCAL_CONTACT:
      case InviteType.MOMENTS:
      case InviteType.BUSINESS:
        const { businessInvite }: LoginPageState = location.state;
        return <InviteHeader requesterName={businessInvite?.requesterAlias || ''} message={businessInvite?.message} />;
      case InviteType.PERSONAL:
        const { personalInvite }: LoginPageState = location.state;
        const requesterName = personalInvite?.inviter.firstName || '';
        return (
          <InviteHeader requesterName={requesterName || ''} message={t('header.addFields.title', { requesterName })} />
        );
      default:
        return <div />;
    }
  };

  const navigateBack = () => {
    if (!location.state) return;
    if (personalLinkId) {
      navigate(`/personalLink?linkId=${personalLinkId}`);
    }

    switch (inviteType) {
      case InviteType.USER:
        const { invite }: LoginPageState = location.state;
        navigate(`/invite?invitationId=${invite?.id}`, { state: location.state });
        return;
      case InviteType.COMMUNITY:
        navigate(`/groups/invite?invitationId=${communityInvitationId}`, { state: location.state });
        return;
      case InviteType.MOMENTS:
      case InviteType.BUSINESS:
        const {
          businessInvite: { version },
        } = location.state;
        navigate(`/moments/invite?invitationId=${invitationId}&type=${version}`, { state: location.state });
        return;
      case InviteType.LOCAL_CONTACT:
        navigate(`/moments/local-invite?invitationId=${invitationId}`, { state: location.state });
        return;
      case InviteType.PERSONAL:
        navigate(`/personal/invite?invitationId=${invitationId}`, { state: location.state });
        break;
      case InviteType.EVENT:
        navigate(`/event?id=${invitationId}`, { state: location.state });
        break;
      case InviteType.DATE_PICKER:
        navigate(`/date-picker?id=${invitationId}`, { state: location.state });
    }
  };

  return (
    <ResponsePageWrapper>
      <ResponsePageWrapper.Header>{getHeader()}</ResponsePageWrapper.Header>
      <div className="flex w-full flex-col gap-4">
        <p className="text-sm">{t('page.login.message')}</p>
        <div className="flex w-full flex-col">
          <label className="focus:border-3 mb-1 pr-2 text-sm focus:border-[#01AFA5] focus:ring-0 focus:ring-transparent">
            {t('form.email.email')}
          </label>
          <input
            className="focus:border-3 h-10 w-full rounded-lg border border-gray-400 px-2 text-gray-600 focus:border-[#01AFA5] focus:ring-0 focus:ring-transparent"
            name="email"
            type="text"
            value={emailInput}
            onChange={(e) => setEmailInput(e.target.value)}
          />
        </div>
        <div className="flex justify-end">
          <Button variant="primary" className="px-[32px]" onClick={handleSubmit}>
            {t('page.login.submit')}
          </Button>
        </div>
        {!hideBackButton && (
          <div className="-mt-2 flex justify-end">
            <span className="text-sm">
              {t('page.login.noAccount')}{' '}
              <strong className="cursor-pointer border-b border-black" onClick={navigateBack}>
                {t('page.login.register')}
              </strong>
            </span>
          </div>
        )}
      </div>
    </ResponsePageWrapper>
  );
}
