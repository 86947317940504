import { parsePhoneNumber } from 'react-phone-number-input';
import {
  BusinessInvitePropertyType,
  BusinessVersion,
  CommunityInvite,
  CustomField,
  CustomFieldResponses,
  CustomFieldType,
  PersonalInvite,
} from '../types/invites';
import { Label, ProfileDataType, ProfileField, Property, PropertyType, SocialType } from '../types/profile';
import { filterCustomLabelFields } from './filterUtils';
import { IsValidField } from './validationUtils';
import { sortProfileFields } from './sortUtils';
import { CommunityData, LinkInfo, LinkType } from '../types/misc';

export const createEmptyField = (dataType: BusinessInvitePropertyType): ProfileField => {
  // if datatype is a social type base shuld be social otherwise just what it is now
  if (Object.values(SocialType).includes(dataType as SocialType)) {
    return {
      social: { socialType: dataType as SocialType, link: '' },
      dataType: ProfileDataType.SOCIAL,
      label: Label.GENERAL,
      description: '',
    };
  }
  const base = {
    dataType: dataType as ProfileDataType,
    label: Label.GENERAL,
    description: '',
  };
  switch (dataType) {
    case ProfileDataType.EMAIL:
      return { ...base, email: '' };
    case ProfileDataType.ADDRESS:
      return {
        ...base,
        address: {
          street: '',
          houseNumber: '',
          houseNumberAddition: '',
          city: '',
          country: '',
          postCode: '',
          countryCode: '',
        },
      };
    case ProfileDataType.PHONENUMBER:
      return {
        ...base,
        phoneNumber: {
          prefix: '',
          suffix: '',
          countryCode: '',
        },
      };
    case ProfileDataType.BIRTHDATE:
      return {
        ...base,
        birthDate: '',
      };
    case ProfileDataType.BUSINESSNAME:
      return base;
    default:
      return base;
  }
};

export const createEmptyCustomFieldResponses = (customFields: CustomField[]): CustomFieldResponses => {
  const labelFields = filterCustomLabelFields(customFields);
  const cnf = customFields.find((field) => field.type === CustomFieldType.COMMUNICATION_NAME);
  return {
    ...(cnf ? { [cnf.customFieldId]: '' } : {}),
    ...labelFields
      .map((field) => ({
        [field.customFieldId]: '',
      }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
  };
};

export const formatCustomFieldResponsesFromCustomFields = (
  fields: CustomField[],
  response: CustomFieldResponses,
): CustomFieldResponses => {
  const labelFields = filterCustomLabelFields(fields);
  const cnf = fields.find((field) => field.type === CustomFieldType.COMMUNICATION_NAME);
  return {
    ...(cnf ? { [cnf.customFieldId]: response[cnf.customFieldId] || '' } : {}),
    ...(labelFields
      .map((field): CustomFieldResponses => {
        if (field.type === CustomFieldType.MULTIPLE_CHOICE) {
          const responseOptions = response[field.customFieldId];
          if (!field.options.includes(responseOptions))
            return {
              [field.customFieldId]: '',
            };
        }

        return {
          [field.customFieldId]: response[field.customFieldId] || '',
        };
      })
      .reduce((acc, curr) => ({ ...acc, ...curr }), {}) || {}),
  };
};

export const removeProfileFieldId = (profileField: ProfileField): ProfileField => {
  const { id, ...rest } = profileField;
  return rest;
};

export const addPhoneNumberCountryCode = (f: ProfileField): ProfileField => {
  if (f.dataType !== ProfileDataType.PHONENUMBER) return f;
  const { country } = parsePhoneNumber(`${f.phoneNumber?.prefix}${f.phoneNumber?.suffix}`) ?? {};
  return {
    ...f,
    phoneNumber: {
      ...f.phoneNumber!,
      countryCode: country!,
    },
  };
};

export const removeIdFromProfileField = (f: ProfileField): ProfileField => {
  const { id, ...rest } = f;
  return rest;
};

export const convertPropertyToProfileField = (p: Property): ProfileField | undefined => {
  switch (p.type) {
    case PropertyType.PHONENUMBER:
      return {
        dataType: ProfileDataType.PHONENUMBER,
        phoneNumber: {
          countryCode: p.countryCode,
          prefix: p.prefix,
          suffix: p.suffix,
        },
        description: '',
        label: Label.GENERAL,
      };
    case PropertyType.ADDRESS:
      const { type, ...address } = p;
      return {
        dataType: ProfileDataType.ADDRESS,
        address,
        description: '',
        label: Label.GENERAL,
      };
    case PropertyType.EMAIL:
      return {
        dataType: ProfileDataType.EMAIL,
        email: p.email,
        description: '',
        label: Label.GENERAL,
      };
    default:
      return undefined;
  }
};

export const convertPersonalInviteToProfileFields = (invite: PersonalInvite): ProfileField[] => {
  if (!invite) return [];
  return sortProfileFields(
    [...invite.data.addresses, ...invite.data.emails, ...invite.data.phoneNumbers]
      .map(convertPropertyToProfileField)
      .map((x, index) => (x ? { ...x, id: `${index}` } : undefined))
      .filter((x) => !!x && IsValidField(x))
      .concat(
        invite?.data.businessName
          ? {
              ...createEmptyField(ProfileDataType.BUSINESSNAME),
              description: invite?.data.businessName!,
              id: 'businessName',
            }
          : [],
      )
      .concat(
        invite.data.birthdate
          ? {
              ...createEmptyField(ProfileDataType.BIRTHDATE),
              birthDate: `${invite.data.birthdate.year}-${(invite.data.birthdate.month + 1)
                .toString()
                .padStart(2, '0')}-${invite.data.birthdate.day.toString().padStart(2, '0')}`,
              id: 'birthdate',
            }
          : [],
      ) as ProfileField[],
  );
};

export function linkInfoToLink(info: LinkInfo): string {
  switch (info.type) {
    case LinkType.BUSINESS_INVITE:
    case LinkType.MOMENTS_INVITE:
      return `/moments/invite?invitationId=${info.data.linkId}&type=${
        info.type === LinkType.MOMENTS_INVITE ? BusinessVersion.MOMENTS : BusinessVersion.COMMUNITY
      }`;
    case LinkType.COMMUNITY_INVITE:
      return `/groups/invite?invitationId=${info.data.linkId}`;
    case LinkType.CONNECTION_INVITE:
      return `/invite?invitationId=${info.data.linkId}`;
    case LinkType.PERSONAL_INVITE:
      return `/personal/invite?invitationId=${info.data.linkId}`;
    case LinkType.PERSONAL_LINK:
      return `/personalLink?linkId=${info.linkId}`;
    case LinkType.LOCAL_CONTACT:
      return `/moments/local-invite?invitationId=${info.data.linkId}`;
    case LinkType.EVENT_INVITE:
      return `/event/invite?id=${info.data.linkId}`;
    case LinkType.EVENT_DATEPICKER_INVITE:
      return `/date-picker/invite?id=${info.data.linkId}`;
    default:
      return '';
  }
}

/**
 * Return all of the existing profile fields for a
 * registration form
 *
 * @returns all profile fields `[EMAIL, PHONENUMBER, ADDRESS, BIRTHDATE]`
 */
export function getAllProfileFields(): ProfileField[] {
  return [
    { label: Label.GENERAL, description: '', dataType: ProfileDataType.EMAIL },
    {
      label: Label.GENERAL,
      description: '',
      dataType: ProfileDataType.PHONENUMBER,
      phoneNumber: { prefix: '', suffix: '', countryCode: '' },
    },
    {
      label: Label.GENERAL,
      description: '',
      dataType: ProfileDataType.ADDRESS,
      address: {
        street: '',
        houseNumber: '',
        houseNumberAddition: '',
        city: '',
        postCode: '',
        country: '',
        countryCode: '',
      },
    },
    { label: Label.GENERAL, description: '', dataType: ProfileDataType.BIRTHDATE },
  ];
}

export function fromCommunityDataToCommunityInvite(data: CommunityData): CommunityInvite {
  return {
    communityId: data.id,
    communityName: data.name,
    communityPicture: data.picture,
    communityDescription: data.description,
    communitySize: data.memberCount,
    inviterId: 0,
    inviterFirstName: '',
    inviterLastName: '',
  };
}
