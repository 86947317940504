/* eslint-disable no-useless-return */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { ProfileDataType, ProfileField } from '../../types/profile';
import {
  BusinessVersion,
  CustomFieldResponses,
  CustomFieldType,
  LocalContactInvite,
} from '../../types/invites';
import { connectionService, invitationService } from '../../services';
import CustomFieldsForm from '../../components/forms/CustomFieldsForm';
import UserDataForm from '../../components/forms/userData/UserDataForm';
import { checkMandatoryFields, isEmptyField } from '../../utils/validationUtils';
import useProfileFields from '../../hooks/useProfileFields';
import ResponsePageWrapper from '../../components/layouts/ResponsePageWrapper';
import InviteHeader from '../../components/headers/InviteHeader';
import useDeleteParams from '../../hooks/useDeleteParams';
import { SuccessPageState } from '../../types/navigation';
import {
  createEmptyCustomFieldResponses,
  formatCustomFieldResponsesFromCustomFields,
} from '../../utils/formatUtils';
import CommunicationNameInput from '../../components/forms/profile/CommunicationNameInput';
import ContactConfirmationModal from '../../components/modals/LocalContactConfirmationModal';
import useAuth from '../../hooks/useAuth';
import AddFieldsHeader from '../../components/headers/AddFieldsHeader';
import { filterPresentFields } from '../../utils/filterUtils';
import NoDataSharedModal from '../../components/modals/NoDataSharedModal';
import Button from '../../components/misc/Button';
import { SharedBusinessData } from '../../types/misc';
import NoRequestedDataCommunityInvitePage from '../reusable/NoRequestedDataCommunityInvitePage';
import { redirectComWebsite } from '../../utils/appUtils';

export default function AuthenticatedLocalContactInvitePage(): JSX.Element {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const profileFields = useProfileFields();
  const auth = useAuth();
  useDeleteParams('link');

  const token = params.get('invitationId');

  const [customFieldResponses, setCustomFieldResponses] = useState<CustomFieldResponses>(
    createEmptyCustomFieldResponses([]),
  );
  const [selectedData, setSelectedData] = useState<ProfileField[]>([]);
  const [invite, setInvite] = useState<LocalContactInvite>();
  const [validate, setValidate] = useState<boolean>(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [noDataSharedModalOpen, setNoDataSharedModalOpen] = useState(false);

  useEffect(() => {
    if (!token) {
      toast.error(t('toast.error.general.invalidInvite'));
      redirectComWebsite();
      return;
    }

    invitationService
      .getLocalContactInvite(token)
      .then((inv) => {
        setInvite(inv);
        const preSelectedValues = filterPresentFields(
          profileFields,
          inv.data.receivedData.filter((f) => !isEmptyField(f)),
        );

        setSelectedData(preSelectedValues);

        setCustomFieldResponses(
          formatCustomFieldResponsesFromCustomFields(
            inv?.customFields,
            inv?.data.customFieldResponses,
          ),
        );
        if (auth?.alias !== inv?.data.alias) setConfirmationModalOpen(true);
      })

      .catch(() => {
        toast.error(t('toast.error.general.inviteNotFound'));
        redirectComWebsite();
      });
  }, []);

  const fetchedSharedData = useRef(false);
  useEffect(() => {
    if (!invite || profileFields.length === 0 || fetchedSharedData.current) return;
    fetchedSharedData.current = true;

    if (invite.version === BusinessVersion.MOMENTS)
      connectionService
        .getSharedDataWithUser(invite.requesterId)
        .then((data) => {
          setSelectedData(data);
        })
        .catch((): void => {});
    else
      connectionService
        .getSharedDataWithBusiness(invite.requesterId)
        .then((): void => {
          navigate('/success', {
            state: {
              translationKey: 'page.common.success.message.existingConnection',
              translationOptions: {
                requesterName: invite.requesterAlias,
              },
              businessInvite: invite,
            },
          });
        })
        .catch((): void => {});
  }, [invite, profileFields]);

  const handleComplete = (): void => {
    if (selectedData.length === 0) setNoDataSharedModalOpen(true);
    else handleSubmit();
  };

  const handleSubmit = async (): Promise<void> => {
    setValidate(true);
    if (!checkMandatoryFields(invite!, selectedData, customFieldResponses)) return;
    const args: SharedBusinessData = {
      propertyIds: selectedData.map((x): string => x.id!),
      customFieldResponses,
      dataForMembers: [],
    };

    await connectionService.createLocalContactConnection(token!, args);

    const state: SuccessPageState = {
      translationKey: `page.common.success.message.${
        invite?.version === BusinessVersion.COMMUNITY ? 'businessCommunityInvite' : 'businessInvite'
      }`,
      translationOptions: {
        businessName: invite?.requesterAlias.replace('+', ' ') || '',
      },
      ...(invite?.version === BusinessVersion.COMMUNITY
        ? { businessInvite: invite! }
        : { requesterId: invite?.requesterId, requesterAlias: invite?.requesterAlias }),
    };

    if (invite?.connectedMembers)
      navigate('/community/members', { state: { invite, success: state }, replace: true });
    else {
      navigate('/success', { state });
      toast.success(t('toast.success.connectionCreated'));
    }
  };

  const handleInviteRequest = async (): Promise<void> => {
    await invitationService.requestLocalContactInvite(token!);
    toast.success(t('toast.success.requestSent'));
    redirectComWebsite();
  };

  const addFields = (fields: ProfileField[]): void => {
    setSelectedData([
      ...selectedData.filter((f) => !fields.some((x) => x.dataType === f.dataType)),
      ...fields,
    ]);
  };

  if (invite && !invite.shareWithAdmins)
    return <NoRequestedDataCommunityInvitePage invite={invite!} onSubmit={handleSubmit} />;

  const requesterName = invite?.requesterAlias.split('+')[0] || '';
  const communicationName = invite?.customFields.find(
    (f) => f.type === CustomFieldType.COMMUNICATION_NAME,
  );

  if (
    invite?.hasAccount &&
    invite.data.receivedData.find((x) => x.dataType === ProfileDataType.EMAIL)?.email !==
      auth?.email
  )
    return (
      <ResponsePageWrapper>
        <ResponsePageWrapper.Header>
          <InviteHeader requesterName={requesterName} message={invite?.message!} />
        </ResponsePageWrapper.Header>
        <div className="flex flex-col">
          <h2 className="font-medium text-lg">
            {t('page.localContactInvite.authWrongAccount.title')}
          </h2>
          <p className="text-sm">
            {t('page.localContactInvite.authWrongAccount.message', {
              inviteeEmail: invite?.data.receivedData.find(
                (x) => x.dataType === ProfileDataType.EMAIL,
              )?.email,
              requesterAlias: invite?.requesterAlias,
            })}
          </p>
        </div>

        <div className="flex flex-1 h-full justify-end items-end align-bottom min-h-10 mt-6">
          <Button className="underline" onClick={() => redirectComWebsite()}>
            {t('general.cancel')}
          </Button>
          <Button variant="primary" className="px-[32px]" onClick={handleInviteRequest}>
            {t('page.localContactInvite.authWrongAccount.button')}
          </Button>
        </div>
      </ResponsePageWrapper>
    );

  return (
    <>
      <ResponsePageWrapper>
        <ResponsePageWrapper.Header>
          <AddFieldsHeader
            requesterName={requesterName}
            message={invite?.message!}
            fields={invite?.data.receivedData}
            addFields={addFields}
          />
        </ResponsePageWrapper.Header>
        <div className="gap-4 flex flex-col flex-1 h-full">
          <div className="flex flex-col gap-2">
            <h2 className="font-medium text-lg">{t('page.shared.requestedData')}</h2>
            <p className="text-sm">{t('page.businessInvite.message', { name: requesterName })}</p>
          </div>

          {communicationName && (
            <div className=" pb-4 border-panel-broder border-b">
              <h2 className="font-medium text-lg mb-1 border-secondary-200 border-t pt-4">
                {t('form.communicationName.label')} {communicationName?.mandatory && '*'}
              </h2>
              <CommunicationNameInput
                name={customFieldResponses[communicationName.customFieldId]}
                validate={validate}
                setName={(name) =>
                  setCustomFieldResponses({
                    ...customFieldResponses,
                    [communicationName.customFieldId]: name,
                  })
                }
                mandatory={communicationName?.mandatory}
              />
            </div>
          )}
          <UserDataForm
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            mandatoryFields={invite?.mandatoryFields || []}
            nonRequestedFields={invite?.nonRequestedFields || []}
            onePerDataType={invite?.version === BusinessVersion.COMMUNITY}
            onlyBirthDate
          />

          <div>
            {invite?.customFields && invite?.customFields.length > 0 && (
              <>
                <h2 className="font-medium text-lg mb-1 border-secondary-200 border-t pt-4">
                  {t('page.shared.requestedFields')}
                </h2>
                <CustomFieldsForm
                  fields={invite?.customFields || []}
                  response={customFieldResponses}
                  setResponse={setCustomFieldResponses}
                />
              </>
            )}
          </div>
          <div className="flex flex-1 h-full justify-end items-end align-bottom min-h-10">
            <Button variant="primary" className="px-[32px]" onClick={handleComplete}>
              {t('page.shared.shareData')}
            </Button>
          </div>
        </div>
      </ResponsePageWrapper>
      <ContactConfirmationModal
        open={confirmationModalOpen}
        setOpen={setConfirmationModalOpen}
        info={invite?.data}
        inviterName={invite?.requesterAlias.replace('+', ' ')}
      />
      <NoDataSharedModal
        open={noDataSharedModalOpen}
        setOpen={setNoDataSharedModalOpen}
        requesterName={requesterName}
        handleComplete={handleSubmit}
      />
    </>
  );
}
