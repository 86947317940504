import { UserAccountCreationEvent } from '../analytics/models';
import { CustomFieldResponses, InviteType } from './invites';
import { EmailPreferenceType } from './misc';
import { ProfileField } from './profile';

export interface InstantRegisterRequest {
  /**
   * The email to register the user with
   */
  email: string;
  /**
   * The alias of the user
   */
  alias: string;
  /**
   * The alias of the person who invited the user
   */
  inviterAlias: string;
  /**
   * type of the invite
   */
  inviteType: InviteType;
  /**
   * Custom data
   */
  customFieldResponses?: CustomFieldResponses;
  /**
   * The invitaion token
   */
  invitationId: string;
  /**
   * The fields to add for the new user
   */
  data: ProfileField[];
  /**
   * The language to send the welcome email with
   */
  lang: Language;
}

export enum Language {
  NL = 'nl',
  EN = 'en',
}

export enum Locale {
  NL = 'nl-NL',
  EN = 'en-US',
}

/**
 * Contains the JWT token to be verified.
 */
export interface LoginRequest {
  /**
   * The JWT to be verified
   */
  magicLinkId: string;

  /**
   * The identifier of the device of the user generated on the frontend user
   */
  deviceUuid?: string;

  /**
   * The otp two factor authentication code.
   */
  otpCode?: string;
}

export interface MagicGenerationRequest {
  /**
   * The email to send the link to
   */
  email: string;

  /**
   * A boolean to see where the user requested the link
   * from the app or from the web
   */
  inApp?: boolean;

  /**
   * Possible invitation id if the user is invited by someone
   */
  invitationId?: string;

  /**
   * Possible community invitation id if the user is invited
   * with a link from a community
   */
  communityInvitationId?: string;

  /**
   * Possible personal invitation id if the user is invited
   * with a link from a personal invite
   */
  personalInvitationId?: string;

  /**
   * The type of the invite in case of a regular/business invite
   */
  inviteType?: InviteType;

  /**
   * The id of the personal link if the
   * user was invited through one
   */
  personalLinkId?: string;
  /**
   * The id of the event if the user was invited
   * to an event
   */
  eventId?: string;
}

export interface CreateConnectionRequest {
  linkId: string;
  dataProvidedIds: string[];
}

export interface CreateCommunityConnectionRequest {
  /**
   * The id of the invite to accept
   */
  inviteId: string;
  /**
   * The ids of the properties to share with the business
   */
  propertyIds: string[];
  /**
   * The custom field responses to share with the business
   */
  customFieldResponses: CustomFieldResponses;
  /**
   * Data for the members of the community
   */
  dataForMembers: string[];
}

export interface CreateMomentConnectionRequest {
  /**
   * The id of the invite to accept
   */
  inviteId: string;
  /**
   * The ids of the properties to share with the business
   */
  propertyIds: string[];
  /**
   * The custom field responses to share with the business
   */
  communicationName?: string;
}

/**
 * The body containing all the information necessary for registration.
 */
export interface RegisterRequest {
  /**
   * The email to register the user with
   */
  email: string;
  /**
   * The alias of the user
   */
  alias: string;

  /**
   * The analytic data of interest of the account creation event
   */
  event: UserAccountCreationEvent;
}

export interface UpdateConnectionRequest {
  /**
   * User whom the properties are shared with.
   */
  targetId: string;

  /**
   * The IDs of the properties shared.
   */
  dataIds: string[];
}

export interface UpdateBusinessConnectionRequest {
  /**
   * The ids of the properties to share with the business
   */
  propertyIds: string[];
  /**
   * The custom field responses to share with the business
   */
  customFieldResponses: CustomFieldResponses;
  /**
   * Token for the local contact in case it is an local contact invite
   */
  localContactToken?: string;

  /**
   * The data for the members of the community
   */
  dataForMembers: string[];

  /**
   * Whether to update the members or not
   */
  updateMembers?: boolean;
}

export interface CheckEmailRequest {
  /**
   * The email to perform operations on
   */
  email: string;
}

export type UseLocalContactInviteRequest = {
  /**
   * The ids of the properties to share with the business
   */
  propertyIds: number[];
  /**
   * The custom field responses to share with the business
   */
  customFieldResponses: CustomFieldResponses;
  /**
   * The data for the members of the community
   */
  dataForMembers: string[];
};

export interface LocalContactInviteLinkParams {
  token: string;
}

export type UsePersonalInviteRequest = {
  accept: boolean;
  sharedProperties: number[];
};

export interface AcceptDataRequestBody {
  /**
   * The id of the data request
   */
  /**
   * The ids of the pieces of data provided
   */
  dataProvidedIds: string[];
}

export interface AcceptIntroductionBody {
  /**
   * The id of the introduction
   */
  introductionId: string;
  /**
   * The ids of the pieces of data provided
   */
  dataProvidedIds: string[];
}

export interface AcceptCommunityRequestBody {
  /**
   * The ids of the shared properties
   */
  sharedProps: number[];
}

export interface UpdatePreferencesBody {
  /**
   * The types of notifications the user doesn't want to receive
   */
  userPreferences: EmailPreferenceType[];
}
