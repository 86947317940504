import { EventCustomField } from './invites';

export type Event = {
  id: number;
  businessId: number;
  title: string;
  memberCount: number;
  description: string;
  location: string;
  locationUrl: string; // URL to the location in Google Maps, filled if location is retrieved from the Google Places Api
  startTime: Date;
  endTime: Date;
  deadline?: Date;
  type: EventType;
  isCancelled: boolean;
  organizer: string;
  role: EventRole;
  ownerId: number;
  picture: string;
  /**
   * The list of members.
   *
   * It is loaded separately from the rest of the event data.
   * Can  be turned into a map, to implement lazy loading.
   */
  participants: EventParticipant[];
  maximumAttendees?: number;
  dateOptions: DateOption[];
  isRecurring: boolean;
  frequency: Frequency;
  isDatePicker: boolean;
  isPicked: boolean;
  recurrenceBatchId: string;
  notificationSettings: NotificationSettings;
  customFields: EventCustomField[];
  recurrenceEndDate?: Date;
};

export type EventParticipant = {
  id: number;
  firstName: string;
  lastName: string;
  picture: string;
  role?: EventRole;
  description: string;
  answer?: EventParticipationAnswer;
  answers?: DateOptionAnswer[];
};

export enum EventRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
}

export enum EventType {
  SOCIAL = 'SOCIAL',
  WORK = 'WORK',
  SPORT = 'SPORT',
  OTHER = 'OTHER',
  DEFAULT = 'DEFAULT',
}

export enum EventParticipationAnswer {
  YES = 'YES',
  NO = 'NO',
  MAYBE = 'MAYBE',
  PENDING = 'PENDING',
}

export enum Frequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  BI_WEEKLY = 'BI_WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export type DateOptionScoredWithSection = Partial<DateOptionScored> & { type: string; title: string };

export interface DateOptionScored extends DateOption {
  score: number;
  counts: { [key in EventParticipationAnswer]: number };
  resolvedAnswerCount: number;
  totalAnswers: number;
  normalizedScore: number;
  percentage: number;
}

export interface DateOption {
  id: number;
  startTime: Date;
  endTime?: Date;
  answers: DateOptionAnswer[];
}

export interface DateOptionAnswer {
  id: number;
  firstName: string;
  lastName: string;
  picture?: string;
  answer: EventParticipationAnswer;
  description?: string;
}

export type NotificationSettings = {
  [userType: string]: {
    [NOTIFICATION_TYPE: string]: {
      setting: boolean;
    };
  };
};

export interface EventLinkData {
  eid: number;
}
