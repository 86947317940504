import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa6';
import { ProfileDataType, ProfileField } from '../../../types/profile';
import { profileService } from '../../../services';
import useProfileFields from '../../../hooks/useProfileFields';
import ProfileFieldModal from '../../modals/ProfileFieldModal';
import Field from './Field';
import Moment from './Moment';
import Social from './Social';
import useAuth from '../../../hooks/useAuth';
import Button from '../../misc/Button';
import { BusinessInvitePropertyType } from '../../../types/invites';

interface UserDataFormProps {
  selectedData: ProfileField[];
  setSelectedData: (selectedData: ProfileField[]) => void;
  nonRequestedFields?: BusinessInvitePropertyType[];
  mandatoryFields?: BusinessInvitePropertyType[];
  onePerDataType?: boolean;
  hasSelectAll?: boolean;
  onlyBirthDate?: boolean;
}

export default function UserDataForm({
  selectedData,
  setSelectedData,
  nonRequestedFields = [],
  mandatoryFields = [],
  onePerDataType = false,
  hasSelectAll = false,
  onlyBirthDate = false,
}: UserDataFormProps): JSX.Element {
  const profileFields = useProfileFields();

  const { t } = useTranslation();
  const auth = useAuth();

  const emails = profileFields.filter((x) => x.dataType === ProfileDataType.EMAIL);
  const phoneNumbers = profileFields.filter((x) => x.dataType === ProfileDataType.PHONENUMBER);
  const addresses = profileFields.filter((x) => x.dataType === ProfileDataType.ADDRESS);
  const businessname = profileFields.filter(
    (x) =>
      !nonRequestedFields.includes(ProfileDataType.BUSINESSNAME) &&
      x.dataType === ProfileDataType.BUSINESSNAME,
  );
  const moments = profileFields.filter(
    (x) =>
      (!onlyBirthDate && x.dataType === ProfileDataType.MOMENT) ||
      x.dataType === ProfileDataType.BIRTHDATE,
  );
  const socialmedia = profileFields.filter(
    (x) =>
      x.dataType === ProfileDataType.SOCIAL &&
      x.social &&
      !nonRequestedFields.includes(x.social?.socialType),
  );

  const hasEmail = !nonRequestedFields.includes(ProfileDataType.EMAIL);
  const hasPhoneNumber = !nonRequestedFields.includes(ProfileDataType.PHONENUMBER);
  const hasAddress = !nonRequestedFields.includes(ProfileDataType.ADDRESS);
  const hasMoment =
    !nonRequestedFields.includes(ProfileDataType.BIRTHDATE) &&
    (onlyBirthDate || moments.length !== 0);

  const [modalDataType, setModalDataType] = useState<ProfileDataType>(ProfileDataType.EMAIL);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!auth) return;
    profileService.getUserData();
  }, [auth?.email]);

  const handleSelectAll = (): void => {
    if (selectedData.length === profileFields.length) setSelectedData([]);
    else setSelectedData(profileFields);
  };

  const nonRequestedFilter = (field: ProfileField): boolean =>
    !nonRequestedFields.includes(field.dataType);

  const handleClick = (active: boolean, field: ProfileField): void => {
    if (active) {
      setSelectedData(selectedData.filter((x) => x.id !== field.id));
    } else if (onePerDataType && field.dataType !== ProfileDataType.SOCIAL)
      setSelectedData([...selectedData.filter((x) => x.dataType !== field.dataType), field]);
    else setSelectedData([...selectedData, field]);
  };

  const handleProfileAdd = (type: ProfileDataType): void => {
    setModalDataType(type);
    setModalOpen(true);
  };

  const renderField = (f: ProfileField): JSX.Element => (
    <Field
      field={f}
      handleClick={handleClick}
      active={selectedData.map((x) => x.id!).includes(f.id!)}
    />
  );

  const renderSocial = (field: ProfileField): JSX.Element => (
    <>
      {field.social && (
        <Social
          mandatory={mandatoryFields.includes(field.social.socialType)}
          field={field}
          handleClick={handleClick}
          active={selectedData.map((x) => x.id!).includes(field.id!)}
        />
      )}
    </>
  );

  const renderMoment = (field: ProfileField) => (
    <Moment
      field={field}
      handleClick={handleClick}
      active={selectedData.map((x) => x.id!).includes(field.id!)}
    />
  );

  const addStar = (type: BusinessInvitePropertyType): string => {
    if (mandatoryFields.includes(type)) return '*';
    return '';
  };

  return (
    <>
      <div className="text-sm">
        {hasSelectAll && (
          <div className="flex justify-end ">
            <div className="w-fit cursor-pointer gap-2 items-center flex" onClick={handleSelectAll}>
              <p className="border-b border-black font-medium">{t('form.userData.selectAll')}</p>
              <input
                type="checkbox"
                checked={selectedData.length === profileFields.length}
                className="form-checkbox border-primary bg-secondary-50 h-5 w-5 text-primary-300 accent-primary-900 rounded-full focus:ring-0 focus:ring-transparent"
              />
            </div>
          </div>
        )}

        {businessname.length > 0 && (
          <div
            className={
              hasAddress || hasPhoneNumber || hasMoment || hasEmail
                ? 'border-secondary-200 border-b pb-4 mb-4'
                : ''
            }>
            <h2 className="font-medium text-lg">{t('form.userData.label.businessName')}</h2>
            <div className=" my-1 gap-2 flex flex-col">{businessname.map(renderField)}</div>
          </div>
        )}

        {hasEmail && (
          <div
            className={
              hasAddress || hasPhoneNumber || hasMoment
                ? 'border-secondary-200 border-b pb-4 mb-4'
                : ''
            }>
            <h2 className="font-medium text-lg">
              {t('form.userData.label.email')} {addStar(ProfileDataType.EMAIL)}
            </h2>
            <div className=" my-1 gap-2 flex flex-col">
              {emails.filter(nonRequestedFilter).map(renderField)}
            </div>
            <Button
              variant="tertiary"
              onClick={() => handleProfileAdd(ProfileDataType.EMAIL)}
              className="mt-4">
              <FaPlus className="inline-block mr-2 h-5 w-5 stroke-2" />
              {t('form.userData.button.email')}
            </Button>
          </div>
        )}

        {hasPhoneNumber && (
          <div className={hasAddress || hasMoment ? 'border-secondary-200 border-b pb-4 mb-4' : ''}>
            <h2 className="font-medium text-lg">
              {t('form.userData.label.phoneNumber')} {addStar(ProfileDataType.PHONENUMBER)}
            </h2>
            <div className=" my-1 gap-2 flex flex-col">
              {phoneNumbers.filter(nonRequestedFilter).map(renderField)}
            </div>
            <Button
              variant="tertiary"
              onClick={() => handleProfileAdd(ProfileDataType.PHONENUMBER)}
              className=" mt-4">
              <FaPlus className="inline-block mr-2 h-5 w-5 stroke-2" />
              {t('form.userData.button.phoneNumber')}
            </Button>
          </div>
        )}
        {hasAddress && (
          <div className={hasMoment ? 'border-secondary-200 border-b pb-4 mb-4' : ''}>
            <h2 className="font-medium text-lg">
              {t('form.userData.label.address')} {addStar(ProfileDataType.ADDRESS)}
            </h2>
            <div className=" my-1 gap-2 flex flex-col">
              {addresses.filter(nonRequestedFilter).map(renderField)}
            </div>
            <Button
              variant="tertiary"
              onClick={() => handleProfileAdd(ProfileDataType.ADDRESS)}
              className="mt-4">
              <FaPlus className="inline-block mr-2 h-5 w-5 stroke-2" />
              {t('form.userData.button.address')}
            </Button>
          </div>
        )}
        {hasMoment && (
          <div className={socialmedia.length > 0 ? 'border-secondary-200 border-b pb-4 mb-4' : ''}>
            <h2 className="font-medium text-lg">
              {onlyBirthDate ? t('form.userData.label.birthDate') : t('form.userData.label.moment')}
              {addStar(ProfileDataType.BIRTHDATE)}
            </h2>
            <div className=" my-1 gap-2 flex flex-col">{moments.map(renderMoment)}</div>
            {onlyBirthDate &&
              profileFields.filter((x) => x.dataType === ProfileDataType.BIRTHDATE).length ===
                0 && (
                <Button
                  variant="tertiary"
                  onClick={() => handleProfileAdd(ProfileDataType.BIRTHDATE)}
                  className=" mt-4">
                  <FaPlus className="inline-block mr-2 h-5 w-5 stroke-2" />
                  {t('form.userData.button.birthDate')}
                </Button>
              )}
          </div>
        )}
        {socialmedia.length > 0 && (
          <div>
            <h2 className="font-medium text-lg">{t('form.userData.label.social')}</h2>
            <div className=" my-1 gap-2 flex flex-col">{socialmedia.map(renderSocial)}</div>
          </div>
        )}
      </div>
      <ProfileFieldModal
        modalType="CREATE"
        type={modalDataType}
        open={modalOpen}
        setOpen={setModalOpen}
      />
    </>
  );
}
